import React, { useEffect, useState, useRef } from "react";
import HeroSection from "../components/HeroSection";
import { Card, Tabs, Typography, Button } from "antd";
import RichTextRender from "../components/RichTextRender/RichTextRender";
import getContentCategory from "../utils/getContentCategory";
import { HomeOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Title } = Typography;

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function Frontpage() {
  const [categories, setCategories] = useState();
  const [mainBoxRes, setMainBoxRes] = useState();
  const [offices, setOffices] = useState();
  /* const [news, setNews] = useState(); */
  const [staff, setStaff] = useState();
  const [activeTab, setactiveTab] = useState("0");

  useEffect(() => {
    getContentCategory("undirsida").then((rsp) => {
      setCategories(rsp.data);
    });

    getContentCategory("box_efst").then((rsp) => {
      const res = rsp.data.items[0];
      setMainBoxRes(res);
    });

    getContentCategory("kiropraktorar").then((rsp) => {
      setOffices(rsp.data);
    });

    /* getContentCategory("news").then((rsp) => {
      setNews(rsp.data);
    }); */

    getContentCategory("staff_member").then((rsp) => {
      const data = {
        ...rsp.data,
        items: rsp.data.items.sort((a, b) => {
          return a.fields.name.localeCompare(b.fields.name);
        }),
      };

      setStaff(data);
    });
  }, []);

  const categoriesRef = useRef(null);
  const findKiroRef = useRef(null);

  const getOfficeFromId = (id) => {
    return offices.items.find((office) => {
      return office.sys.id === id;
    });
  };

  return (
    <>
      <HeroSection vheight={75} id="frontMain">
        <div id="mainMenu">
          <Button
            onClick={() => {
              setTimeout(() => {
                findKiroRef.current.scrollIntoView({
                  behavior: "smooth",
                });
              }, 100);
            }}
            size="large"
          >
            Finna Kírópraktor
          </Button>
          {categories &&
            categories.items
              .sort((a, b) => {
                return (a.fields.weight || 999) - (b.fields.weight || 999);
              })
              .map((category, key) => {
                const fields = category.fields;

                return (
                  <Button
                    onClick={() => {
                      setactiveTab(key.toString());

                      setTimeout(() => {
                        categoriesRef.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }, 100);
                    }}
                    size="large"
                    key={key}
                  >
                    {fields.titill}
                  </Button>
                );
              })}
        </div>
        <>
          <div
            className="text-center"
            style={{ color: "#fff", fontSize: 26, marginTop: 90 }}
          >
            <img
              style={{ transform: "rotate(90deg)", width: 75 }}
              src="/images/logo.svg"
              alt="Logo"
            />
          </div>
          <br />
          <div className="main_cards_frontpage">
            <Card loading={!!!mainBoxRes} title={mainBoxRes?.fields.titill}>
              {mainBoxRes && (
                <RichTextRender
                  ellipsis={{
                    rows: 10,
                    expandable: true,
                    symbol: "Lesa meira",
                  }}
                  content={mainBoxRes?.fields.texti}
                  includes={mainBoxRes.includes}
                ></RichTextRender>
              )}
            </Card>
          </div>
        </>
      </HeroSection>

      {/*       <HeroSection className="text-center" vheight={75}>
        <Title>Fréttir</Title>

        {news &&
          news.items.map((item, key) => {
            return (
              <Card
                title={item.fields.titill}
                loading={!!!news}
                className="contentCard newsItem"
                key={key}
              >
                <RichTextRender
                  ellipsis={{
                    rows: 20,
                    expandable: true,
                    symbol: "Lesa meira",
                  }}
                  className="richTextContent"
                  includes={news.includes}
                  content={item.fields.text}
                ></RichTextRender>
              </Card>
            );
          })}
      </HeroSection>
 */}

      <div ref={categoriesRef}>
        <HeroSection vheight={10}>
          <Tabs
            activeKey={activeTab}
            centered
            onChange={(key) => {
              setactiveTab(key.toString());
            }}
          >
            {categories &&
              categories.items
                .sort((a, b) => {
                  return (a.fields.weight || 999) - (b.fields.weight || 999);
                })
                .map((category, key) => {
                  const fields = category.fields;

                  return (
                    <TabPane
                      tab={capitalizeFirstLetter(fields.titill)}
                      key={key}
                    >
                      <Card
                        className="contentCard"
                        style={{
                          maxWidth: 1100,
                          margin: "auto",
                          border: "none",
                          textAlign: "justify",
                        }}
                      >
                        <RichTextRender
                          ellipsis={{
                            rows: 35,
                            expandable: true,
                            symbol: "Lesa meira",
                          }}
                          className="richTextContent"
                          includes={categories.includes}
                          content={fields.efni}
                        ></RichTextRender>
                      </Card>
                    </TabPane>
                  );
                })}
          </Tabs>
        </HeroSection>
      </div>

      <HeroSection className="betweener drawing" vheight={40}></HeroSection>

      <HeroSection id="findKiro" vheight={50}>
        <div ref={findKiroRef} className="text-center mt20">
          <Title>Finna Kírópraktor</Title>
        </div>

        {!!staff?.items.length && !!offices?.items.length && (
          <div className="offices">
            {staff.items.map((staffRes, key) => {
              const fields = staffRes.fields;
              const { name, phone, email, stofur } = fields;

              const _offices = stofur
                ? stofur.map((_office) => {
                    const officeId = _office.sys.id;
                    const officeObj = getOfficeFromId(officeId);

                    return officeObj;
                  })
                : [];

              return (
                <Card key={key} title={name}>
                  {phone && <div>{phone}</div>}
                  {email && (
                    <div>
                      <a href={"mailto:" + email}>{email}</a>
                    </div>
                  )}

                  {(phone || email) && <br />}

                  {!!_offices.length &&
                    _offices.map((o, key) => {
                      return (
                        <div className="office" key={key}>
                          <h5>
                            {" "}
                            <HomeOutlined /> {o.fields.titill}
                          </h5>
                          {o.fields.email && (
                            <div>
                              <a href={"mailto:" + o.fields.email}>
                                {o.fields.email}
                              </a>
                            </div>
                          )}

                          <div>{o.fields.phone}</div>
                          {o.fields.street && o.fields.postalCodeAndTown && (
                            <div>
                              {o.fields.street}, {o.fields.postalCodeAndTown}
                            </div>
                          )}

                          {o.fields.website && (
                            <div>
                              <a
                                href={o.fields.website}
                                rel="noreferrer noopener"
                                target="_blank"
                              >
                                {o.fields.website}
                              </a>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </Card>
              );
            })}
          </div>
        )}
      </HeroSection>
    </>
  );
}
