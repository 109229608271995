import Axios from "axios";

const spaceId = "wmyq7dnp565f";
const accessToken = "KVnUcGlZHj6hBOPmRsqFd8Od07NJ91fdQjd9bgX6lcY";

const getContentCategory = (category) =>
  Axios.get(
    `https://cdn.contentful.com/spaces/${spaceId}/environments/master/entries/?content_type=${category}&access_token=${accessToken}`
  );

export default getContentCategory;
