import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { apiEndpoint } from "./prismic-configuration";
import { NotFound } from "./pages";

import Page from "./pages/Page";
import Frontpage from "./pages/Frontpage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "antd/dist/antd.css";

const App = (props) => {
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint);
  const repoName = repoNameArray[1];

  return (
    <>
      <Helmet>
        <script
          async
          defer
          src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`}
        />
      </Helmet>

      <Header></Header>

      <div style={{ minHeight: "calc(100vh - 135px)" }}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Frontpage} />
            <Route exact path="/page/:uid" component={Page} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </div>

      <Footer>
        <div style={{ textAlign: "center", color: "#fff" }}>
          <div>
            <div>
              <img style={{ width: 30 }} src="/images/logo.svg" alt="Logo" />
            </div>
          </div>

          <br></br>
          <div>Kírópraktorafélag Íslands</div>
          <span>Laugavegur 163 E | </span>
          <span>105 Reykjavík | </span>
          <span>Sími 553 4400 | </span>
          <span>
            <a
              style={{ color: "#fff" }}
              href="mailto:kiropraktik@kiropraktik.is"
            >
              kiropraktik@kiropraktik.is
            </a>
          </span>
        </div>
      </Footer>
    </>
  );
};

export default App;
