import React, { useState, useEffect } from "react";
import { Layout, Modal } from "antd";
import RichTextRender from "../components/RichTextRender/RichTextRender";
import getContentCategory from "../utils/getContentCategory";

const { Header: AntHeader } = Layout;

export default function Header() {
  const [modal, setModal] = useState(false);
  const [englishInfo, setEnglishInfo] = useState("");

  useEffect(() => {
    getContentCategory("englishInfo").then((rsp) => {
      setEnglishInfo(rsp.data);
    });
  }, []);

  return (
    <>
      <AntHeader className="header">
        <div>
          <a className="logo" href="/">
            <img src="/images/logo.svg" alt="Logo" />
          </a>
          <span id="logo_text">Kírópraktorafélag Íslands</span>
        </div>

        <div className="ukFlag">
          <img
            onClick={() => {
              setModal(true);
            }}
            src="/images/uk_flag.svg"
            alt="UK Flag"
          />
        </div>
      </AntHeader>

      {englishInfo && (
        <Modal
          visible={modal}
          onCancel={() => {
            setModal(false);
          }}
          footer={null}
        >
          <RichTextRender
            content={englishInfo?.items?.[0].fields?.text}
            includes={englishInfo.includes}
          ></RichTextRender>
        </Modal>
      )}
    </>
  );
}
